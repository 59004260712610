import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  ToggleButton,
  Typography,
} from '@mui/material'
import { FilterList, Help } from '@mui/icons-material'
import { ALL_GARMENTS, PRICE_PROFILES, TAILORING_GRADES, formatDateLong, getMaxDate } from '@trinity/utils'
import { ToggleButtonGroup } from '../../components'
import { useFilters, useGlobalState } from '../../hooks'

interface FabricPricesProps {
  fabricPrices: TrinityAPI.PriceType[]
}

export function FabricPrices({ fabricPrices }: FabricPricesProps) {
  const { onMobile } = useGlobalState()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [params, setParams] = useSearchParams()
  const [calculatorValue, setCalculatorValue] = useState(params.get('calculatorType') ?? 'margin')
  const dateArray = fabricPrices.map(price => price.garmentPrices?.updatedAt).filter((date): date is Date => !!date)
  const maxDate = dateArray.length > 0 ? `Updated ${formatDateLong(getMaxDate(dateArray))}` : ''
  const { Filters, FilterChips } = useFilters({ open: drawerOpen, close: () => setDrawerOpen(false), filters })

  return (
    <Stack direction='row' spacing={4}>
      <Filters>
        <PriceCalculator />
        <input type='hidden' name='calculatorType' value={calculatorValue} />
        <ToggleButtonGroup
          value={calculatorValue}
          onChange={(_e, v) => {
            if (v) {
              setCalculatorValue(v)
              setParams(params => {
                params.set('calculatorType', v)
                return params
              })
            }
          }}
          sx={{ width: 'fit-content' }}
        >
          <ToggleButton value='margin'>Profit Margin</ToggleButton>
          <ToggleButton value='markup'>Markup Factor</ToggleButton>
        </ToggleButtonGroup>
      </Filters>
      <Stack spacing={4} sx={{ width: 1 }}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ md: 'center' }}
          justifyContent='space-between'
          spacing={{ xs: 2, md: 0 }}
        >
          <Stack alignItems='flex-start' spacing={1}>
            <Typography variant='h2'>Fabric Price List</Typography>
            <Typography variant='body2' sx={{ pl: 0.25 }}>
              {maxDate}
            </Typography>
          </Stack>
          {onMobile ? (
            <Stack direction='row' justifyContent='space-between'>
              <CurrencySelect />
              <OptionPricesButton />
            </Stack>
          ) : (
            <Stack alignItems='flex-start' spacing={1}>
              <OptionPricesButton />
              <DownloadCSVButton fabricPrices={fabricPrices} maxDate={maxDate} />
            </Stack>
          )}
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
          <Stack spacing={2} sx={{ width: { xs: 1, md: 1 / 4 } }}>
            <Typography>Tailoring Grade</Typography>
            <Select
              name='tailoringGrade'
              labelId='tailoring-grade'
              id='tailoring-grade-select'
              value={params.get('tailoringGrade') ?? TAILORING_GRADES[0].name}
              onChange={e =>
                setParams(params => {
                  params.set('tailoringGrade', e.target.value)
                  return params
                })
              }
              sx={{ height: 45 }}
            >
              {TAILORING_GRADES.map(tailoringGrade => (
                <MenuItem key={tailoringGrade.name} value={tailoringGrade.name}>
                  {tailoringGrade.description}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          {onMobile ? (
            <Button variant='outlined' sx={{ ml: 1 }} startIcon={<FilterList />} onClick={() => setDrawerOpen(true)}>
              Filters
            </Button>
          ) : (
            <CurrencySelect />
          )}
        </Stack>
        <FilterChips />
        <PriceListTable fabricPrices={fabricPrices} />
      </Stack>
    </Stack>
  )
}

function PriceCalculator() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [params] = useSearchParams()

  return (
    <Stack direction='row'>
      <TextField
        name='priceCalculator'
        type='number'
        label='Retail Price Calculator'
        defaultValue={params.get('priceCalculator')}
        placeholder='1'
        inputProps={{ inputMode: 'decimal', min: 0, step: '0.1', sx: { ':invalid': { color: 'error.main' } } }}
        sx={{ typography: 'body2', height: 45, mx: -0.5 }}
      />
      <IconButton disableRipple size='small' onClick={e => setAnchorEl(e.currentTarget)} sx={{ mt: -6 }}>
        <Help sx={{ fontSize: 19, color: 'grey.300' }} />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => setAnchorEl(null)}
      >
        <Stack p={2} maxWidth={350} spacing={2}>
          <Typography variant='body1'>Profit Margin</Typography>
          <Typography variant='smallBody1'>
            Enter a percentage less than 100% to generate the retail price required for you to realize that percentage
            of profit margin. Profit margin is what percentage of the retail price you would keep as profit.
          </Typography>
          <Typography variant='smallBody3'>
            A 60% profit margin on a $100 wholesale price would require a retail price of $250.
          </Typography>
          <Divider />
          <Typography variant='body1'>Markup Factor</Typography>
          <Typography variant='smallBody1'>
            Enter a number greater than 0 to generate the retail price required for you to realize that markup multiple.
            A markup is how many times the wholesale price you want to set the retail price.
          </Typography>
          <Typography variant='smallBody3'>
            A 2.5 markup on a $100 wholesale price would require a retail price of $250.
          </Typography>
        </Stack>
      </Popover>
    </Stack>
  )
}

function PriceListTable({ fabricPrices }: FabricPricesProps) {
  const { onMobile } = useGlobalState()
  const filteredPrices = fabricPrices.filter(price => Object.keys(price.garmentPrices).length > 0)
  const [params] = useSearchParams()
  const tailoringGradeName = params.get('tailoringGrade') ?? TAILORING_GRADES[0].name
  const tailoringGrade = TAILORING_GRADES.find(grade => grade.name === tailoringGradeName)

  if (filteredPrices.length < 1) {
    return (
      <Typography variant='body1' align='center'>
        No Results Found
      </Typography>
    )
  }

  return (
    <Stack>
      <Stack
        direction='row'
        alignItems='center'
        height={28}
        bgcolor='primary.main'
        px={4}
        position='sticky'
        top={0}
        zIndex={999}
      >
        <Typography
          variant='body1'
          color='common.white'
          textAlign={{ xs: 'center', md: 'start' }}
          sx={{ flex: 1, textTransform: 'uppercase' }}
        >
          code
        </Typography>
        {!onMobile &&
          tailoringGrade?.garmentTypes.map(garmentType => (
            <Typography
              key={garmentType.abbreviation}
              variant='body1'
              color='common.white'
              sx={{ flex: 1, textTransform: 'uppercase' }}
            >
              {garmentType.alternateName ?? garmentType.formalName}
            </Typography>
          ))}
      </Stack>
      <Stack spacing={2}>
        {filteredPrices.map(price => (
          <Accordion key={price.code}>
            <AccordionSummary>
              <Typography variant='h6' textAlign={{ xs: 'center', md: 'start' }} sx={{ flex: 1 }}>
                {price.code.toUpperCase()}
              </Typography>
              {!onMobile &&
                tailoringGrade?.garmentTypes.map(garmentType => (
                  <Typography variant='body1' key={garmentType.abbreviation} sx={{ flex: 1.15 }}>
                    {price.garmentPrices[garmentType.abbreviation]?.toFixed(2) ?? '--'}
                  </Typography>
                ))}
            </AccordionSummary>
            <AccordionDetails sx={{ border: 'n100', bgcolor: 'grey.50' }}>
              <Stack divider={<Divider />} spacing={2} px={{ xs: 1, md: 6 }} py={2}>
                {ALL_GARMENTS.map(garment => {
                  if (price.garmentPrices[garment.abbreviation]) {
                    return (
                      <Stack key={garment.abbreviation} direction='row' spacing={2}>
                        <Typography noWrap variant='body1' sx={{ flex: { xs: 3, sm: 1 } }}>
                          {garment.alternateName ?? garment.formalName}
                        </Typography>
                        <Typography variant='h6' sx={{ flex: 1 }}>
                          {price.garmentPrices[garment.abbreviation]?.toFixed(2) ?? '--'}
                        </Typography>
                      </Stack>
                    )
                  }
                  return null
                })}
                {tailoringGradeName === 'id-dress' && (
                  <Typography variant='htable' color='primary.main'>
                    Please Note: All Buckingham Garments Are An Additional $50
                  </Typography>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Stack>
  )
}

const CurrencySelect = () => {
  const [params, setParams] = useSearchParams()

  return (
    <Select
      name='currency'
      variant='standard'
      labelId='currency'
      id='currency-select'
      value={params.get('currency') ?? PRICE_PROFILES[0].id}
      onChange={e =>
        setParams(params => {
          params.set('currency', String(e.target.value))
          return params
        })
      }
      sx={{ width: 120 }}
    >
      {PRICE_PROFILES.map(profile => (
        <MenuItem key={profile.id} value={profile.id}>
          {profile.title} ({profile.symbol})
        </MenuItem>
      ))}
    </Select>
  )
}

const OptionPricesButton = () => {
  const navigate = useNavigate()
  return (
    <Button size='small' variant='outlined' onClick={() => navigate('../options')}>
      View Option Prices
    </Button>
  )
}

//* HELPERS

const filters = {
  price: [
    { title: '$', filterParam: 'priceTier', value: '1' },
    { title: '$$', filterParam: 'priceTier', value: '2' },
    { title: '$$$', filterParam: 'priceTier', value: '3' },
    { title: '$$$$', filterParam: 'priceTier', value: '4' },
  ],
  'price code': [
    { title: 'a', filterParam: 'priceCode', value: 'a' },
    { title: 'c', filterParam: 'priceCode', value: 'c' },
    { title: 'd', filterParam: 'priceCode', value: 'd' },
    { title: 'e', filterParam: 'priceCode', value: 'e' },
    { title: 'k', filterParam: 'priceCode', value: 'k' },
    { title: 'm', filterParam: 'priceCode', value: 'm' },
    { title: 'n', filterParam: 'priceCode', value: 'n' },
    { title: 'p', filterParam: 'priceCode', value: 'p' },
    { title: 's', filterParam: 'priceCode', value: 's' },
    { title: 't', filterParam: 'priceCode', value: 't' },
    { title: 'u', filterParam: 'priceCode', value: 'u' },
    { title: 'v', filterParam: 'priceCode', value: 'v' },
    { title: 'x', filterParam: 'priceCode', value: 'x' },
    { title: 'y', filterParam: 'priceCode', value: 'y' },
    { title: 'z', filterParam: 'priceCode', value: 'z' },
  ],
  usage: [
    { title: 'clothing', filterParam: 'namedFilter', value: 'suiting' },
    { title: 'shirting', filterParam: 'namedFilter', value: 'shirting' },
  ],
}

interface DownloadCSVButtonProps {
  fabricPrices: TrinityAPI.PriceType[]
  maxDate: string
}

function DownloadCSVButton({ fabricPrices, maxDate }: DownloadCSVButtonProps) {
  const filteredPrices = fabricPrices.filter(price => Object.keys(price.garmentPrices).length > 0)
  const [params] = useSearchParams()
  const tailoringGradeName = params.get('tailoringGrade') ?? TAILORING_GRADES[0].name
  const tailoringGradeDescription = TAILORING_GRADES.find(grade => grade.name === tailoringGradeName)?.description ?? ''
  const currencyId = params.get('currency') ?? PRICE_PROFILES[0].id
  const currencyTitle = PRICE_PROFILES.find(profile => profile.id === Number(currencyId))?.title ?? ''

  const downloadCSV = () => {
    const csvlines = []

    csvlines.push([`Price list for ${tailoringGradeDescription}`])
    csvlines.push([`"${maxDate}"`]) // quote date string that includes a comma
    csvlines.push([currencyTitle])

    const csvheaders = ['Price Code']
    ALL_GARMENTS.map(gt => csvheaders.push(gt.alternateName ?? gt.formalName))
    csvlines.push(csvheaders)

    const csvdata = filteredPrices.map(price => {
      const csvline = [price.code]
      ALL_GARMENTS.map(garment => {
        csvline.push(price.garmentPrices[garment.abbreviation]?.toFixed(2) ?? '')
      })

      return csvline
    })

    csvlines.push(...csvdata)
    const csvfile = csvlines.map(line => line.join(',')).join('\r\n')

    /* Idiomatic force click to start download from blob url */
    const blob = new Blob([csvfile], {
      type: 'text/plain;charset=utf-8',
    })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.download = `${tailoringGradeName}-${currencyTitle}.csv`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Button size='small' variant='outlined' onClick={() => downloadCSV()}>
      Download CSV
    </Button>
  )
}
